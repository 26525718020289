import type { RouteResourceResponse } from '../../../../../common/types.tsx';
import { getAccessedAt } from '../accessed-at/index.tsx';
import { DEFAULT_RESOURCE_MAX_AGE } from '../create-resource/constants.tsx';
import { getExpiresAt } from '../expires-at/index.tsx';
import { BASE_DEFAULT_STATE_SLICE } from './constants.tsx';

export const getDefaultStateSlice = (): RouteResourceResponse => ({
	...BASE_DEFAULT_STATE_SLICE,
	expiresAt: getExpiresAt(DEFAULT_RESOURCE_MAX_AGE),
	accessedAt: getAccessedAt(),
});
