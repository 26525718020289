import getMeta from '@atlassian/jira-get-meta';
import type { Flags } from '../../common/types.tsx';

const fromMetaTag = (() => {
	// ajs-fe-feature-flags contains ALL frontend flags (boolean, string and JSON flags)
	const locator = () => getMeta('ajs-fe-feature-flags');

	return {
		locator,
		processor: () => {
			const featureFlags = locator();
			if (featureFlags != null && featureFlags !== '') {
				try {
					return JSON.parse(featureFlags);
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
				} catch (e: any) {
					throw new Error("Can't parse 'ajs-fe-feature-flags' value");
				}
			}
			return undefined;
		},
	};
})();

type WindowFlagVars = {
	featureFlags: Flags | undefined;
	ssr__feFeatureFlags: Flags | undefined;
	ssr__allFeatureFlags: Flags | undefined;
	isReorderEarlyScriptsEnabled: boolean | undefined;
	invalidateFeatureFlags_DO_NOT_USE: boolean | undefined;
};

const createGlobalVarChecker = (name: keyof WindowFlagVars) => {
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const globalVar = (typeof window !== 'undefined'
		? window
		: globalThis) as unknown as WindowFlagVars;

	return {
		locator: () => name in globalVar,
		processor: () => globalVar[name],
	};
};
const fromFeatureFlagsGlobal = createGlobalVarChecker('featureFlags');
const fromSsrFeFeatureFlagsGlobal = createGlobalVarChecker('ssr__feFeatureFlags');
const fromSsrAllFeatureFlagsGlobal = createGlobalVarChecker('ssr__allFeatureFlags');

const FLAG_TEST_ORDER = [
	fromFeatureFlagsGlobal,
	fromMetaTag,
	fromSsrAllFeatureFlagsGlobal,
	fromSsrFeFeatureFlagsGlobal,
];

export const _haveValuesArrived = (): boolean => {
	return FLAG_TEST_ORDER.some((tester) => tester.locator());
};

export const nonMemoizedGetAllFeatureFlags = (): Flags => {
	const featureFlags = FLAG_TEST_ORDER.find((tester) => tester.locator())?.processor();

	return featureFlags || {};
};

let memoizedFeatureFlags: Flags | undefined;
const memoizedGetAllFeatureFlags = (): Flags => {
	/**
	 * The following `isReorderEarlyScriptsEnabled` and `invalidateFeatureFlags_DO_NOT_USE` branch
	 * is a highly temporary (couple of weeks) block of code that is intended for use only for
	 * HOT mitigation #hot-110667 where we need for a short period (couple of weeks) to freeze
	 * a subset of the feature flags that are only used in the Issue View early entry script.
	 * The feature flags are set inside the `src/entry-fs` initially and then updated later
	 * after the early entry script has executed.
	 *
	 * The `window.isReorderEarlyScriptsEnabled` is a value that is set in the bifrost-template
	 * and is based on a bifrost-gateway feature-flag value. We are using this as a potential to
	 * mitigate further HOTs by turning it off.
	 */
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const globalVar = (typeof window !== 'undefined'
		? window
		: globalThis) as unknown as WindowFlagVars;

	if (globalVar.isReorderEarlyScriptsEnabled === true) {
		if (memoizedFeatureFlags == null || globalVar.invalidateFeatureFlags_DO_NOT_USE === true) {
			globalVar.invalidateFeatureFlags_DO_NOT_USE = false;
			memoizedFeatureFlags = nonMemoizedGetAllFeatureFlags();
		}
		return memoizedFeatureFlags;
	}

	if (memoizedFeatureFlags == null) {
		memoizedFeatureFlags = nonMemoizedGetAllFeatureFlags();
	}
	return memoizedFeatureFlags;
};

export default memoizedGetAllFeatureFlags;
