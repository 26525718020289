import getMeta from '@atlassian/jira-get-meta';

const TAP_VALUES: Record<string, string> = {
	f: 'free',
	s: 'standard',
	p: 'premium',
};

const getTapValue = (editionShorthand: string) => TAP_VALUES[editionShorthand] ?? 'UNLICENSED';

export const getCustomAttributes = () => {
	try {
		const tenantContextEditions = JSON.parse(getMeta('ajs-product-editions') || '{}');

		return {
			'jira-software_edition': getTapValue(tenantContextEditions['jira-software']),
			'jira-servicedesk_edition': getTapValue(tenantContextEditions['jira-servicedesk']),
			'jira-workmanagement_edition': getTapValue(tenantContextEditions['jira-core']),
		};
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		// eslint-disable-next-line no-console
		console.warn(`Error parsing application editions from tenant context: ${error}`);

		return {
			'jira-software_edition': 'UNLICENSED',
			'jira-servicedesk_edition': 'UNLICENSED',
			'jira-workmanagement_edition': 'UNLICENSED',
		};
	}
};

type WindowStatsigValues = { _statsigValues?: Record<string, unknown> };

const valuesFromGlobal = (() => {
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const globalVar = (typeof window !== 'undefined' ? window : globalThis) as WindowStatsigValues;
	return {
		locator: () => '_statsigValues' in globalVar,
		processor: () => globalVar._statsigValues,
	};
})();

const valuesFromMeta = (() => {
	const locator = () => getMeta('ajs-fe-statsig-values');
	return {
		locator,
		processor: () => {
			const featureFlags = locator();
			if (featureFlags != null && featureFlags !== '') {
				try {
					return JSON.parse(featureFlags);
				} catch {
					throw new Error("Can't parse 'ajs-fe-statsig-values' value");
				}
			}
		},
	};
})();

export const _haveValuesArrived = (): boolean => {
	return Boolean(valuesFromGlobal.locator() || valuesFromMeta.locator());
};

export const getGateValues = (): Record<string, unknown> | undefined => {
	if (valuesFromGlobal.locator()) {
		return valuesFromGlobal.processor();
	}
	if (valuesFromMeta.locator()) {
		return valuesFromMeta.processor();
	}
	return undefined;
};
