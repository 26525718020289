import camelCase from 'lodash/camelCase';
import startCase from 'lodash/startCase';

export const CORE_PROJECT = 'business' as const;
export const SOFTWARE_PROJECT = 'software' as const;
export const SERVICE_DESK_PROJECT = 'service_desk' as const;
export const CUSTOMER_SERVICE_PROJECT = 'customer_service' as const;
export const SERVICE_DESK_PROJECT_ANALYTICS = 'serviceDesk' as const;
export const PRODUCT_DISCOVERY_PROJECT = 'product_discovery' as const;
export const JIRA_PROJECT_TYPE_CORE_PROJECT = 'BUSINESS' as const;
export const JIRA_PROJECT_TYPE_SOFTWARE_PROJECT = 'SOFTWARE' as const;
export const JIRA_PROJECT_TYPE_SERVICE_DESK_PROJECT = 'SERVICE_DESK' as const;
export const JIRA_PROJECT_TYPE_CUSTOMER_SERVICE_PROJECT = 'CUSTOMER_SERVICE' as const;
export const JIRA_PROJECT_TYPE_PRODUCT_DISCOVERY_PROJECT = 'PRODUCT_DISCOVERY' as const;
export const JIRA_PROJECT_STATUS_ACTIVE = 'ACTIVE' as const;
export const JIRA_PROJECT_STATUS_ARCHIVED = 'ARCHIVED' as const;
export const JIRA_PROJECT_STATUS_DELETED = 'DELETED' as const;
export const COMPANY_MANAGED_PROJECT = 'COMPANY_MANAGED_PROJECT' as const;
export const TEAM_MANAGED_PROJECT = 'TEAM_MANAGED_PROJECT' as const;

// Should it be renamed to ProductType?
export type ProjectType =
	| typeof CORE_PROJECT
	| typeof SOFTWARE_PROJECT
	| typeof SERVICE_DESK_PROJECT
	| typeof PRODUCT_DISCOVERY_PROJECT
	| typeof CUSTOMER_SERVICE_PROJECT;

// Jira Project types in AGG.
export type JiraProjectType =
	| typeof JIRA_PROJECT_TYPE_CORE_PROJECT
	| typeof JIRA_PROJECT_TYPE_SOFTWARE_PROJECT
	| typeof JIRA_PROJECT_TYPE_SERVICE_DESK_PROJECT
	| typeof JIRA_PROJECT_TYPE_CUSTOMER_SERVICE_PROJECT
	| typeof JIRA_PROJECT_TYPE_PRODUCT_DISCOVERY_PROJECT;

// Jira Project Styles in AGG.
export type JiraProjectStyle = typeof COMPANY_MANAGED_PROJECT | typeof TEAM_MANAGED_PROJECT;

// Jira Project statuses in AGG.
export type JiraProjectStatus =
	| typeof JIRA_PROJECT_STATUS_ACTIVE
	| typeof JIRA_PROJECT_STATUS_ARCHIVED
	| typeof JIRA_PROJECT_STATUS_DELETED;
export const ProjectStyle = {
	CLASSIC: 'classic',
	NEXT_GEN: 'next-gen',
} as const;
export type ProjectStyle = (typeof ProjectStyle)[keyof typeof ProjectStyle];

// Same as ProjectStyle, just a newer notation; 'CMP' and 'TMP' are newer names for 'classic' and 'next-gen'. These values are used by a different set of APIs (compared to `ProjectStyle`)
export const ProjectStyleCmpNotation = {
	COMPANY_MANAGED: 'companyManaged',
	TEAM_MANAGED: 'teamManaged',
} as const;
export type ProjectStyleCmpNotation =
	(typeof ProjectStyleCmpNotation)[keyof typeof ProjectStyleCmpNotation];

type ProjectTypeForAnalytics =
	| typeof CORE_PROJECT
	| typeof SOFTWARE_PROJECT
	| typeof SERVICE_DESK_PROJECT_ANALYTICS
	| typeof CUSTOMER_SERVICE_PROJECT
	| typeof PRODUCT_DISCOVERY_PROJECT;
export const PROJECT_TYPES: ProjectType[] = [
	CORE_PROJECT,
	SOFTWARE_PROJECT,
	SERVICE_DESK_PROJECT,
	CUSTOMER_SERVICE_PROJECT,
	PRODUCT_DISCOVERY_PROJECT,
];
export const toProjectType = (value?: string | null): ProjectType | undefined =>
	PROJECT_TYPES.find((projectType) => projectType === value);
/**
 * Transforms a Jira project type into a more generic project type for internal use.
 * This function aids in abstracting away specific Jira project type details.
 */
export function convertToProjectType(jiraProjectType: JiraProjectType): ProjectType {
	switch (jiraProjectType) {
		case JIRA_PROJECT_TYPE_CORE_PROJECT:
			return CORE_PROJECT;
		case JIRA_PROJECT_TYPE_SOFTWARE_PROJECT:
			return SOFTWARE_PROJECT;
		case JIRA_PROJECT_TYPE_SERVICE_DESK_PROJECT:
			return SERVICE_DESK_PROJECT;
		case JIRA_PROJECT_TYPE_CUSTOMER_SERVICE_PROJECT:
			return SERVICE_DESK_PROJECT;
		case JIRA_PROJECT_TYPE_PRODUCT_DISCOVERY_PROJECT:
			return PRODUCT_DISCOVERY_PROJECT;
		default:
			throw new Error(`Invalid JiraProjectType: ${jiraProjectType}`);
	}
}

/**
 * Convert to text that's shown in UI.
 * No i18n is used, as we don't translate Jira project type atm in UI (e.g. in Project Settings -> Details page, or Global Settings -> Products)
 */
export function convertToProjectTypeUiText(jiraProjectType: JiraProjectType): string {
	// At the time of writing, convertToProjectType returned "Service Desk" not "Customer Service"; this is a workaround for that
	if (jiraProjectType === JIRA_PROJECT_TYPE_CUSTOMER_SERVICE_PROJECT) {
		return startCase(CUSTOMER_SERVICE_PROJECT);
	}

	const convertedProjectType = convertToProjectType(jiraProjectType);
	if (convertedProjectType === SERVICE_DESK_PROJECT) {
		// 'Service Desk' is old branding
		return startCase('Service Management');
	}

	return startCase(convertedProjectType);
}

/**
 * Transforms generic project type to a Jira project type.
 */
export const convertToJiraProjectType = (projectType: ProjectType): JiraProjectType => {
	switch (projectType) {
		case SOFTWARE_PROJECT:
			return JIRA_PROJECT_TYPE_SOFTWARE_PROJECT;
		case SERVICE_DESK_PROJECT:
			return JIRA_PROJECT_TYPE_SERVICE_DESK_PROJECT;
		case CUSTOMER_SERVICE_PROJECT:
			return JIRA_PROJECT_TYPE_CUSTOMER_SERVICE_PROJECT;
		case CORE_PROJECT:
			return JIRA_PROJECT_TYPE_CORE_PROJECT;
		case PRODUCT_DISCOVERY_PROJECT:
			return JIRA_PROJECT_TYPE_PRODUCT_DISCOVERY_PROJECT;
		default:
			throw new Error(`Invalid ProjectType: ${projectType}`);
	}
};

/**
 * Maps a given project type to its corresponding analytics project type.
 * It ensures that the project type is compatible with the analytics system.
 * Returns undefined if the project type doesn't have an analytics equivalent.
 */
export const toAnalyticsProjectType = (
	projectType?: ProjectType,
): ProjectTypeForAnalytics | undefined => {
	switch (projectType) {
		case SERVICE_DESK_PROJECT: {
			return SERVICE_DESK_PROJECT_ANALYTICS;
		}
		case CUSTOMER_SERVICE_PROJECT: {
			return CUSTOMER_SERVICE_PROJECT;
		}
		case SOFTWARE_PROJECT: {
			return SOFTWARE_PROJECT;
		}
		case PRODUCT_DISCOVERY_PROJECT: {
			return PRODUCT_DISCOVERY_PROJECT;
		}
		case CORE_PROJECT: {
			return CORE_PROJECT;
		}
		default: {
			return undefined;
		}
	}
};
const getFullProjectTypeKey = (style: ProjectStyle, projectType: ProjectType) =>
	camelCase(`${style}_${projectType}`);
export const CLASSIC_CORE_PROJECT_TYPE = getFullProjectTypeKey(ProjectStyle.CLASSIC, CORE_PROJECT);
export const NEXT_GEN_SOFTWARE_TYPE = getFullProjectTypeKey(
	ProjectStyle.NEXT_GEN,
	SOFTWARE_PROJECT,
);
export type CanonicalId =
	| 'jira-core'
	| 'jira-software'
	| 'jira-servicedesk'
	| 'jira-customer-service'
	| 'jira-product-discovery'
	| 'jira';
/**
 * Converts a project type into a canonical ID string used for identification across different systems.
 * This standardization facilitates easier recognition and interoperability of project types.
 * In cases where the project type is unrecognized, it defaults to a general 'jira' ID, ensuring that every project type is categorizable.
 */
export const projectTypeToCanonicalId = (projectType: ProjectType | null): CanonicalId => {
	switch (projectType) {
		case CORE_PROJECT:
			return 'jira-core';
		case SOFTWARE_PROJECT:
			return 'jira-software';
		case SERVICE_DESK_PROJECT:
			return 'jira-servicedesk';
		case CUSTOMER_SERVICE_PROJECT:
			return 'jira-customer-service';
		case PRODUCT_DISCOVERY_PROJECT:
			return 'jira-product-discovery';
		default:
			return 'jira';
	}
};

export type Project = {
	avatarUrl: string;
	id: number;
	isSimplified: boolean;
	key: string;
	name: string;
	style: ProjectStyle;
	type: string;
};
