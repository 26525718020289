import { fg } from '@atlassian/jira-feature-gating';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import type {
	InvariantRoutes,
	MatchedRoute,
	MatchedInvariantRoute,
	Query,
	Routes,
	Route,
} from '../../types.tsx';
import matchRouteOrderIndependent from '../match-route-order-independent/index.tsx';
import matchRouteOrderDependent from '../match-route-order-dependent/index.tsx';

const isLegacyRoutes = (routes: Routes): boolean => {
	// a naive way to check if the routes are legacy routes
	return routes[routes.length - 1]?.name === 'legacy-fallback';
};

export const matchInvariantRoute = (
	routes: InvariantRoutes,
	pathname: string,
	queryParams: Query | undefined,
	basePath = '',
): MatchedInvariantRoute | null => {
	const useOrderIndependentMatching =
		isLegacyRoutes(routes) === false && fg('order-independent-route-matching');
	const matchRoute = useOrderIndependentMatching
		? matchRouteOrderIndependent
		: matchRouteOrderDependent;
	return matchRoute(routes, pathname, queryParams, basePath);
};

/**
 * Performance optimisation to fast-match a single route
 * instead of looping thorugh all defined routes
 */
export const warmupMatchRouteCache = (
	route: Route,
	pathname: string,
	queryParams: Query | undefined,
	basePath = '',
) => {
	// no need to check legacy routes because `warmupMatchRouteCache` is used for SPA only
	const matchRoute = fg('order-independent-route-matching')
		? matchRouteOrderIndependent
		: matchRouteOrderDependent;
	return matchRoute([route], pathname, queryParams, basePath);
};

// check if the below routes are ACTUALLY used on production?
const routesToCheckUsage = ['servicedesk-reports-summary'];

const defaultMatchRoute = (
	routes: Routes,
	pathname: string,
	queryParams: Query | undefined,
	basePath = '',
): MatchedRoute | null => {
	const useOrderIndependentMatching =
		isLegacyRoutes(routes) === false && fg('order-independent-route-matching');

	const matchRoute = useOrderIndependentMatching
		? matchRouteOrderIndependent
		: matchRouteOrderDependent;
	const matchedRoute = matchRoute(routes, pathname, queryParams, basePath);

	if (!__SERVER__ && fg('log-route-servicedesk-reports-summary')) {
		if (matchedRoute?.route.name && routesToCheckUsage.includes(matchedRoute.route.name)) {
			log.safeInfoWithoutCustomerData('match_route', 'result', {
				name: matchedRoute.route.name,
			});
		}
	}

	// if there is an error from the order-independent matching, fallback to order-dependent matching
	if (useOrderIndependentMatching && matchedRoute === null) {
		return matchRouteOrderDependent(routes, pathname, queryParams, basePath);
	}

	return matchedRoute;
};

export default defaultMatchRoute;
