import React, { type ReactNode } from 'react';
import type {
	RouterContext,
	RouteResource,
	RouteResourceResponse,
} from '../../../common/types.tsx';
import type { RouteResourceUpdater } from '../../common/types.tsx';

import { useResource } from '../use-resource/index.tsx';

type Props<T> = {
	children: (
		resource: RouteResourceResponse<T> & {
			update: (getNewData: RouteResourceUpdater<T>) => void;
			refresh: () => void;
		},
	) => ReactNode;
	resource: RouteResource<T>;
	options?: {
		routerContext?: RouterContext;
	};
};

export const ResourceSubscriber = <T,>({ children, resource, options }: Props<T>) => {
	const result = useResource(resource, options);

	return <>{children(result)}</>;
};
