import memoizeOne from 'memoize-one';
import supportedLocales, {
	type Locale,
} from '@atlassian/jira-common-constants/src/supported-locales.tsx';

import getMeta, { type MetaGetter } from '@atlassian/jira-get-meta';
import getXsrfToken from '@atlassian/jira-platform-xsrf-token/src/index.tsx';
import {
	type BaseUrl,
	type CloudId,
	type ActivationId,
	type CloudName,
	toBaseUrl,
	toAccountId,
	type AccountId,
} from '@atlassian/jira-shared-types/src/general.tsx';
import {
	populateApplicationLicenseStates,
	LICENSE_STATE_ACTIVE,
	LICENSE_STATE_UNLICENSED,
} from '@atlassian/jira-shared-types/src/license-state.tsx';
import {
	type ProductCrossSellConfig,
	type SiteAdminStatus,
	type TenantContext,
	type Environment,
	toEnvironment,
	populateApplicationPermissions,
} from '@atlassian/jira-shared-types/src/tenant-context.tsx';
import determineApplicationEditions from './edition/index.tsx';
import determineApplicationLicenseStates from './license-state/index.tsx';
import determineProductPermissionGates from './product-permission-gates/index.tsx';

export const LICENSED_PRODUCTS = {
	ATLASSIAN_ANALYTICS: 'atlassian-analytics-free',
	JIRA_SOFTWARE: 'jira-software',
	JIRA_SERVICEDESK: 'jira-servicedesk',
	JIRA_PORTFOLIO: 'com.radiantminds.roadmaps-jira',
	JIRA_CORE: 'jira-core',
	JIRA_PRODUCT_DISCOVERY: 'jira-product-discovery',
	CONFLUENCE: 'confluence',
	OPSGENIE: 'opsgenie',
	BITBUCKET: 'com.atlassian.bitbucket',
	STATUS_PAGE: 'statuspage',
	LOOM: 'loom',
	COMPASS: 'compass',
} as const;
/**
 * Identifies if the current user has admin rights based on site metadata.
 */
export const determineSiteAdminStatus = (getMetaCustom?: MetaGetter): SiteAdminStatus => {
	const getMetaImpl = getMetaCustom === undefined ? getMeta : getMetaCustom;
	const metaValue = getMetaImpl('ajs-is-admin');
	if (metaValue === null) {
		return 'unknown';
	}
	return metaValue === 'true' ? 'admin' : 'non-admin';
};
/**
 * Retrieves a list of licensed products available to the user from site metadata.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getLicensedProducts = (getMetaCustom?: MetaGetter): any => {
	const getMetaImpl = getMetaCustom === undefined ? getMeta : getMetaCustom;
	try {
		return JSON.parse(getMetaImpl('ajs-licensed-products') || '{}');
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		// eslint-disable-next-line no-console
		console.warn(`Invalid list of licensed products: ${error}`);
		return {};
	}
};
/**
 * Fetches user's product entitlement details from site metadata.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getProductEntitlementDetails = (getMetaCustom?: MetaGetter): any => {
	const getMetaImpl = getMetaCustom === undefined ? getMeta : getMetaCustom;
	try {
		return JSON.parse(getMetaImpl('ajs-product-entitlement-details') || '{}');
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		return {};
	}
};
export const getInitialProductList = (getMetaCustom?: MetaGetter): string => {
	const getMetaImpl = getMetaCustom === undefined ? getMeta : getMetaCustom;
	const metaValue = getMetaImpl('ajs-initial-product-list');
	if (metaValue === null) {
		return '';
	}
	return metaValue;
};
/**
 * Extracts the first activation date from a meta tag.
 * Parses the date as a timestamp, returning null if the date is not set or cannot be parsed.
 */
export const getFirstActivationDate = (getMetaCustom?: MetaGetter): number | null => {
	const getMetaImpl = getMetaCustom === undefined ? getMeta : getMetaCustom;
	const metaValue = getMetaImpl('ajs-first-activation-date');
	if (metaValue === null) {
		return null;
	}
	const timestamp = parseInt(metaValue, 10);
	if (Number.isNaN(timestamp)) {
		return null;
	}
	return timestamp;
};

export const getAppUpdatesAvailable = (getMetaCustom?: MetaGetter): number | null => {
	const getMetaImpl = getMetaCustom === undefined ? getMeta : getMetaCustom;
	const metaValue = getMetaImpl('ajs-app-updates-available');
	if (metaValue === null) {
		return null;
	}

	const hasAppUpdates = parseInt(metaValue, 10);

	if (Number.isNaN(hasAppUpdates)) {
		return null;
	}

	return hasAppUpdates;
};

/**
 * Determines if product cross-selling is enabled based on site configuration.
 */
export const determineProductCrossSellConfig = (
	getMetaCustom?: MetaGetter,
): ProductCrossSellConfig => {
	const getMetaImpl = getMetaCustom === undefined ? getMeta : getMetaCustom;
	const metaValue = getMetaImpl('ajs-xflow-product-suggestions-enabled');
	if (metaValue === null) {
		return 'unknown';
	}
	return metaValue === 'true' ? 'enabled' : 'disabled';
};
export const getEnvironment = (): Environment | null => toEnvironment(getMeta('ajs-environment'));

export const _haveValuesArrived = (): boolean => {
	return (
		// Cloud ID is flushed early. Checking only this means critical values are available, but not "all of them".
		typeof getMeta('ajs-cloud-id') === 'string' &&
		// Version number is retrieved via slow metadata provider call. its presence should indicate "the rest" are available, too.
		typeof getMeta('ajs-version-number') === 'string'
	);
};

/**
 * Use hooks from @atlassian/jira-tenant-context-controller instead of this function. Because reading from 30 <meta> tags in DOM on each call slows page down considerably.
 * More info: https://hello.atlassian.net/wiki/spaces/MAGMA/blog/2022/01/12/1479453433/PSA+getTenantContext+is+now+deprecated
 */
const getTenantContextFromMeta = (getMetaCustom?: MetaGetter): TenantContext => {
	const getMetaImpl = getMetaCustom === undefined ? getMeta : getMetaCustom;
	const baseUrl: BaseUrl = toBaseUrl('');
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
	const cloudId: CloudId = getMetaImpl('ajs-cloud-id') as any;
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
	const activationId: ActivationId = getMetaImpl('ajs-activation-id') as any;
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
	const cloudName: CloudName = getMetaImpl('ajs-cloud-name') as any;
	const xsrfToken =
		getMetaImpl === getMeta ? getXsrfToken() || null : getMetaImpl('atlassian-token');
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const languageTag: string | null = getMetaImpl('ajs-fspage-locale') as string | null;
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
	const locale: Locale = getMetaImpl('ajs-user-locale') as any;
	const userFullname = getMetaImpl('ajs-remote-user-fullname');
	const username = getMetaImpl('ajs-remote-user');
	const isAdmin = getMetaImpl('ajs-is-admin') === 'true';
	const isSiteAdmin = getMetaImpl('ajs-is-site-admin') === 'true';
	const fabricActivityUrl = '/gateway/api/activity';
	const fabricNotificationLogUrl = '/gateway/api/notification-log';
	const fabricPfDirUrl = getMetaImpl('ajs-fabric-pf-dir-url');
	const appPermissions = populateApplicationPermissions({
		hasSoftwareAccess: getMetaImpl('ajs-has-jira-software') === 'true',
		hasServiceDeskAccess: getMetaImpl('ajs-has-jira-service-desk') === 'true',
		hasCoreAccess: getMetaImpl('ajs-has-jira-core') === 'true',
		hasProductDiscoveryAccess: getMetaImpl('ajs-has-jira-product-discovery') === 'true',
	});
	const accountId = getMetaImpl('ajs-atlassian-account-id');
	const isAnonymous = accountId === '';
	const applicationKey = getMetaImpl('ajs-application-key');
	const environment = toEnvironment(getMetaImpl('ajs-environment'));
	const areProductSuggestionsEnabled =
		getMetaImpl('ajs-xflow-product-suggestions-enabled') === 'true';
	const licensedProducts = getLicensedProducts(getMetaImpl);
	const productEntitlementDetails = getProductEntitlementDetails(getMetaImpl);
	if (accountId === null || locale === null || cloudId === null) {
		throw new Error('Unable to find tenant context');
	}
	if (!supportedLocales.includes(locale)) {
		// eslint-disable-next-line no-console
		console.warn(`Non supported locale in tenant context: ${locale}`);
	}
	const siteAdminStatus = determineSiteAdminStatus(getMetaImpl);
	const productCrossSellConfig = determineProductCrossSellConfig(getMetaImpl);
	const appEditions = determineApplicationEditions(getMetaImpl);
	const productPermissionGated = determineProductPermissionGates(getMetaImpl);
	let licenseStates;
	if (getMetaImpl('ajs-product-license-states') !== null) {
		licenseStates = determineApplicationLicenseStates(getMetaImpl);
	} else {
		/**
		 * When feature flag is OFF, the value of licenseStates will just depend on ajs-has-* tags.
		 * At the moment, there's no other usages besides <LicenseStateCheck>, so this is safe
		 */
		licenseStates = populateApplicationLicenseStates({
			core: appPermissions.hasCoreAccess ? LICENSE_STATE_ACTIVE : LICENSE_STATE_UNLICENSED,
			software: appPermissions.hasSoftwareAccess ? LICENSE_STATE_ACTIVE : LICENSE_STATE_UNLICENSED,
			serviceDesk: appPermissions.hasServiceDeskAccess
				? LICENSE_STATE_ACTIVE
				: LICENSE_STATE_UNLICENSED,
			customerService: appPermissions.hasCustomerServiceAccess
				? LICENSE_STATE_ACTIVE
				: LICENSE_STATE_UNLICENSED,
			productDiscovery: appPermissions.hasProductDiscoveryAccess
				? LICENSE_STATE_ACTIVE
				: LICENSE_STATE_UNLICENSED,
		});
	}
	const loggedInUser = username === '' ? null : username;
	const atlassianAccountId: AccountId | null = accountId === '' ? null : toAccountId(accountId);
	const isDevMode = getMetaImpl('ajs-dev-mode') === 'true';
	const firstActivationDateMs = getFirstActivationDate(getMetaImpl);
	const isDevopsProjectCreationScenario =
		getMetaImpl('ajs-devops-project-creation-scenario') === 'true';
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const devopsProjectSignupSource: string | null = getMetaImpl(
		'ajs-devops-signup-source-scenario',
	) as string | null;
	const isMarketingOptOut = getMetaImpl('ajs-marketing-opt-out') === 'true';
	const hasDlpEnabled = getMetaImpl('ajs-has-dlp') === 'true';
	const appUpdatesAvailable = getAppUpdatesAvailable(getMetaImpl);
	const avpWorkspaceId = getMetaImpl('ajs-avp-workspace-id');

	return {
		appEditions,
		applicationKey,
		appPermissions,
		appUpdatesAvailable,
		areProductSuggestionsEnabled,
		atlassianAccountId,
		baseUrl,
		cloudId,
		activationId,
		cloudName,
		devopsProjectSignupSource,
		environment,
		fabricActivityUrl,
		fabricNotificationLogUrl,
		fabricPfDirUrl,
		firstActivationDateMs,
		isAdmin,
		isAnonymous,
		isDevMode,
		isDevopsProjectCreationScenario,
		isMarketingOptOut,
		isSiteAdmin,
		languageTag,
		licensedProducts,
		licenseStates,
		locale,
		loggedInUser,
		productCrossSellConfig,
		productEntitlementDetails,
		productPermissionGated,
		siteAdminStatus,
		userFullname,
		xsrfToken,
		hasDlpEnabled,
		avpWorkspaceId,
	};
};

/**
 * @deprecated
 * Use of this function has performance concerns due to it having to access over 30 meta tags *every time it is called*.
 * Use hooks from @atlassian/jira-tenant-context-controller instead. More info: https://hello.atlassian.net/wiki/spaces/MAGMA/blog/2022/01/12/1479453433/PSA+getTenantContext+is+now+deprecated
 */
export const getTenantContext_DEPRECATED_DO_NOT_USE = memoizeOne(getTenantContextFromMeta);

/*
 * @deprecated For test onnly
 */
// eslint-disable-next-line camelcase
export const getTenantContextFromMeta_FOR_TEST = getTenantContextFromMeta;
