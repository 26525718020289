import type { Route } from '../types.tsx';

const deduplicateResources = (route: Route) => {
	// Apply resource de-duping to the route
	if ('resources' in route && Array.isArray(route.resources)) {
		Object.assign(route, {
			resources: [...new Set(route.resources)],
		});
	}
};

const extractlazyOpts = (route: Route) => {
	if ('lazyOpts' in route && typeof route.lazyOpts === 'function') {
		const opts = route.lazyOpts();
		Object.assign(route, opts);
		// eslint-disable-next-line no-param-reassign
		delete route.lazyOpts;
	}
};

// This function mutates the route object by applying a series of transformations.
// such as deduplicating resources
// and calling the lazyOpts function to extract the lazy options and apply them to the route.
// (This is to defer the evaluation of the lazy options until the route is actually matched)
const unsafeMutateRoute = (route: Route) => {
	// make sure `extractlazyOpts` is called before `deduplicateResources`
	// because `resources` is a lazy option
	[extractlazyOpts, deduplicateResources].forEach((fn) => fn(route));
};

export default unsafeMutateRoute;
